//@ts-nocheck
import { FocusComponents } from '@ponbike/mixed-storyblok-components/dist/focus/mixed-storyblok-components.js';

export default defineNuxtPlugin(nuxtApp => {
    const baseUrl = window?.baseUrl || process.env.BASE_URL || ''; // TODO Check

    nuxtApp.vueApp.provide('baseUrl', baseUrl);

    nuxtApp.vueApp.use(FocusComponents, {
        storyblok: nuxtApp.$config.public.storyblok
    });
});
